import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import Pricing from '../Pricing'
import SubscribeForm from "../SubscribeForm"

const PricingPageTemplate = ({
  title,
  subtitle,
  meta_title,
  meta_description,
  pricing,
}) => (
  <div className="bg-white flex flex-col">
    <div className="container mx-auto px-8">
      <Helmet>
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
      </Helmet>

      <div className="bg-cover lg:bg-contain bg-top bg-no-repeat -mx-16" style={{backgroundImage: `linear-gradient(to bottom, rgba(255,255,255,.5) 60%, rgba(255,255,255,1) 95%),url("/img/Mask Group 4@2x.png")`}}>
        <div className="mx-16">

          {/* hero */}
          <main className="flex flex-wrap items-center text-center md:text-left my-12 py-48 lg:px-24">
            <div className="w-full md:w-full whitespace-pre-line">
              <h1 className="text-5xl text-gray-800 leading-tight mb-6 font-bold">
                {title}
              </h1>
              <h2 className="text-2xl text-gray-700 mb-12 font-medium">
                {subtitle}
              </h2>
              <h4 className="text-l text-indigo-500 mb-2 font-medium">
                Sign up now to be notified when we launch!
              </h4>
              <SubscribeForm inputPlaceholder1=""/>
            </div>
          </main>

          {/*<h2 className='has-text-weight-semibold is-size-2'>
            {pricing.heading}
          </h2>
          <p className='text-md'>{pricing.description}</p>

          <section className='flex flex-wrap mb-4 my-12'>
            <Pricing data={pricing.plans} />
          </section>*/}
        </div>
      </div>
    </div>
  </div>
)

PricingPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

export default PricingPageTemplate
